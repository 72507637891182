<template>
  <b-card>
        <contact-edit-tab :contact-data="contactData"  class="mt-2 pt-75" />
  </b-card>


</template>

<script>
import ContactEditTab from '@/views/hr/orientation/contact/ContactEditTab'
import {
  VBToggle, VBModal,BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BFormGroup,BFormSelect,BSidebar
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import orientationStoreModule from '@/views/hr/orientation/orientationStoreModule'

export default {
  name: 'contact-edit',
  components: {

    ContactEditTab,VBToggle, VBModal,BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BFormGroup,BFormSelect,BSidebar
  },
  data(){
    return {
      id: router.currentRoute.params.id

    }
  },
  setup(){
    const contactData =ref(null)

    const CONTACT_APP_STORE_MODULE = 'hr-contact'
    // Register module
    if (!store.hasModule(CONTACT_APP_STORE_MODULE)) store.registerModule(CONTACT_APP_STORE_MODULE, orientationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONTACT_APP_STORE_MODULE)) store.unregisterModule(CONTACT_APP_STORE_MODULE)
    })

    store.dispatch('fetchContact', { id: router.currentRoute.params.id })
        .then(response => { contactData.value = response.data })
        .catch(error => {

          if (error.response.status === 404) {
            contactData.value = undefined
          }
        })

    return {
      contactData
    }
  }

}
</script>

<style scoped>

</style>
